<template>
  <div>
    <van-nav-bar :title="title" left-arrow @click-left="$router.back()">
    </van-nav-bar>
    <iframe :src="url" frameborder="no" ref="frame" scrolling="auto" :height="frameHeight" :width="frameWidth"
      style="position: fixed; top: 46; right: 0; left: 0; z-index: 99"></iframe>
  </div>
</template>

<script>
export default {
  data() {
    return {
      title: "可视化报表",
      url: "",
      frameHeight: 0,
      frameWidth: 0,
    };
  },
  components: {},
  created() {
    this.onLoad();
  },

  updated() { console.log("frame updated") },
  watch: {
    $route(to, from) {
      //监听路由是否变化
      if (to.path == "/frame" && to.query != from.query) {
        this.onLoad();
        this.frameOnload();
      }
    },
  },
  mounted() {
    this.frameOnload();
  },
  computed: {},
  methods: {
    async onLoad() {
      let para = this.$route.query;
      if (!para || !para.url) {
        return;
      }
      this.title = para.title || this.title;
      this.url = para.url;
    },
    frameOnload() {
      let frameElem = this.$refs.frame;
      this.frameHeight = document.documentElement.clientHeight - 46;
      this.frameWidth = document.documentElement.clientWidth;
      let contentWindow = frameElem.contentWindow;

      let frameLoaded = () => {
        if (this.url.indexOf("/grafana") != -1) {
          let hideMenu = this.url.indexOf("hideMenu=true") != -1;
          let hideHeader = this.url.indexOf("hideHeader=true") != -1;
          let hideSubMenuControlsLink =
            this.url.indexOf("hideSubMenuControlsLink=true") != -1;
          let styleContent = '';
          if (hideMenu)
            styleContent += ' .grafana-app nav.sidemenu { display:none } ';
          if (hideHeader)
            styleContent += ' .grafana-app header:not(.panel-title-container) { display:none } ';
          if (hideSubMenuControlsLink)
            styleContent += ' .submenu-controls .gf-form .gf-form-label--dashlink { display:none } ';

          // Check if the style element with the specified ID exists
          let styleId = 'custom-style-grafana'; // Replace 'custom-style' with the desired ID
          let existingStyle = contentWindow.document.getElementById(styleId);

          let style = contentWindow.document.createElement("style");
          style.type = "text/css";
          style.id = styleId; // Set the ID for the style element
          style.innerHTML = styleContent;

          // If the style element with the ID already exists, replace it
          if (existingStyle) {
            existingStyle.parentNode.replaceChild(style, existingStyle);
          } else {
            // Otherwise, just append the new style element to the document
            contentWindow.document.querySelector('.grafana-app').appendChild(style);
          }
        }
      };
      if (frameElem.attachEvent) {
        frameElem.attachEvent("onload", frameLoaded);
      } else {
        frameElem.onload = frameLoaded;
      }
      // 在父级窗口中监听resize事件
      window.addEventListener('resize', () => {
        this.frameHeight = window.innerHeight - 46;
        this.frameWidth = window.innerWidth;
      });

    },
  },
};
</script>
